import { Static, Type } from "@sinclair/typebox";
import { PubSubMessage } from "../message";
// --------
// Entity Schemas
// --------
export const mediaFormLabels = {
  NEWS_AGENCY: "News agency",
  ONLINE: "Online",
  PRINT: "Print",
  RADIO_STATION: "Radio station",
  SOCIAL_MEDIA: "Social media",
  TELEVISION: "Television",
} as const;

export const mediaForms: (keyof typeof mediaFormLabels)[] = Object.keys(
  mediaFormLabels
) as (keyof typeof mediaFormLabels)[];

export const mediaSubFormLabels = {
  NA: "N/A",
  ONLINE_NEWS: "Online News",
  PROFESSIONAL_JOURNALS: "Professional Journals",
  WEBSITES: "Websites",
  DAILY_NEWSPAPERS: "Daily Newspapers",
  FEED: "Feed",
  CONSUMER_PUBLICATIONS: "Consumer Publications",
  BLOGS: "Blogs",
  NEWS_AGENCIES: "News Agencies",
  CLASSIC_TV: "Classic TV",
  WEEKLY_NEWSPAPERS: "Weekly Newspapers",
  ADVERTISING_PAPERS: "Advertising Papers",
  CLASSIC_RADIO: "Classic Radio",
  PRESS_PORTALS: "Press Portals",
  CUSTOMER_MAGAZINES: "Customer Magazines",
  FORUMS: "Forums",
  NEWSLETTER: "Newsletter",
  SOCIAL_NETWORKS: "Social Networks",
  INTERNET_TV: "Internet TV",
  VIDEO_SITES: "Video Sites",
  INTERNET_RADIO: "Internet Radio",
  PICTURE_SITES: "Picture Sites",
  MICROBLOGS: "Microblogs",
  QA_SITES: "QA Sites",
  REVIEW_SITES: "Review Sites",
  PODCAST: "Podcast",
} as const;

export const mediaSubForms: (keyof typeof mediaSubFormLabels)[] = Object.keys(
  mediaSubFormLabels
) as (keyof typeof mediaSubFormLabels)[];

const OEmbedType = Type.Union([
  Type.Literal("rich"),
  Type.Literal("photo"),
  Type.Literal("video"),
  Type.Literal("link"),
]);

const OEmbedSchema = Type.Optional(
  Type.Object({
    version: Type.String({ maxLength: 10 }),
    type: OEmbedType,
    url: Type.String({ format: "uri" }),
    provider_name: Type.String({ maxLength: 100 }),
    provider_url: Type.String({ format: "uri" }),
    html: Type.String(),
    width: Type.Integer(),
    height: Type.Integer(),
    author_name: Type.String({ maxLength: 100 }),
    author_url: Type.Optional(Type.String({ format: "uri" })),
  })
);

export const InboxApiRecordSchema = Type.Object(
  {
    id: Type.String(),
    ingestionId: Type.String(),
    productId: Type.String(),
    publishedAt: Type.String(),
    processedAt: Type.String(),
    updatedAt: Type.String(),
    matchedSubscriptions: Type.Array(
      Type.Object({
        id: Type.String(),
        name: Type.String(),
        highlights: Type.Array(Type.String()),
      })
    ),
    content: Type.String(),
    headline: Type.String(),
    subHeadline: Type.Optional(Type.String()),
    author: Type.String(),
    language: Type.String(),
    country: Type.Optional(Type.String()),
    deeplink: Type.Optional(Type.String()),
    pages: Type.Optional(Type.String()),
    section: Type.Optional(Type.String()),
    images: Type.Array(
      Type.Object({
        url: Type.String(),
        caption: Type.String(),
        selected: Type.Boolean(),
      })
    ),
    broadcastData: Type.Optional(
      Type.Object({
        stationId: Type.Optional(Type.Number()),
        duration: Type.Optional(Type.Number()),
        url: Type.Optional(Type.String()),
        tvEyesSearchId: Type.Optional(Type.String()),
        publicUrl: Type.Optional(Type.String()),
      })
    ),
    clippingLayoutUrl: Type.Optional(Type.String()),
    mediaId: Type.Number(),
    mediaName: Type.String(),
    mediaForm: Type.Union(
      mediaForms.map((mediaForm) => Type.Literal(mediaForm))
    ),
    mediaSubForm: Type.Optional(
      Type.Union([
        ...mediaSubForms.map((mediaType) => Type.Literal(mediaType)),
        Type.Null(),
      ])
    ),
    mediaSubIssue: Type.Optional(Type.String()),
    supplierId: Type.String(),
    supplierIdShape: Type.Number(),
    supplierDocumentId: Type.String(),
    customHeadline: Type.Optional(Type.String()),
    snippet: Type.Optional(Type.String()),
    abstracts: Type.Array(
      Type.Object({
        isoCode: Type.String(),
        content: Type.String(),
      })
    ),
    tags: Type.Array(
      Type.Object({
        id: Type.String(),
        type: Type.Union([Type.Literal("TEXTUAL"), Type.Literal("IMAGE")]),
        subjectAreaId: Type.Optional(Type.Number()),
        localizations: Type.Array(
          Type.Object({
            isoCode: Type.String(),
            name: Type.String(),
          })
        ),
      })
    ),
    status: Type.Union([
      Type.Literal("PROPOSED"),
      Type.Literal("REJECTED"),
      Type.Literal("CONFIRMED"),
      Type.Literal("CONFIRMED_AUTOMATICALLY"),
    ]),
    priority: Type.Optional(
      Type.Union([
        Type.Literal("NONE"),
        Type.Literal("ONE"),
        Type.Literal("TWO"),
        Type.Literal("THREE"),
        Type.Literal("FOUR"),
      ])
    ),
    productType: Type.Union([
      Type.Literal("NEWSFEED"),
      Type.Literal("MEDIA_REVIEW"),
    ]),
    sortSection: Type.Optional(
      Type.Object({
        id: Type.String(),
        name: Type.String(),
        rank: Type.Number(),
      })
    ),
    issueId: Type.Optional(Type.String()),
    productPublicationTarget: Type.Array(
      Type.Union([
        Type.Literal("TBML"),
        Type.Literal("briefings"),
        Type.Literal("inbox-api"),
        Type.Literal("legacy-mind"),
      ])
    ),
    publicationTarget: Type.Array(Type.Union([Type.Literal("TBML")])),
    source: Type.Union([
      Type.Literal("PROCESSING_RULE"),
      Type.Literal("SUBSCRIPTION_TRIGGER"),
      Type.Literal("IMPORT_APP_STM"),
      Type.Literal("IMPORT_APP_PMG"),
      Type.Literal("IMPORT_APP_MANUAL"),
      Type.Literal("IMPORT_API"),
    ]),
    group: Type.Optional(
      Type.Object({
        relatedTo: Type.String(),
        order: Type.Number(),
      })
    ),
    oEmbed: OEmbedSchema,
  },
  { additionalProperties: true }
);

export const InboxApiArticlePayload = Type.Object({
  mediaId: Type.Number(),
  language: Type.String(),
  publishedAt: Type.String(),
  headline: Type.String(),
  subHeadline: Type.Optional(Type.String()),
  country: Type.Optional(Type.String()),
  author: Type.Optional(Type.String()),
  bodyText: Type.String(),
  deeplink: Type.Optional(Type.String()),
  status: Type.Union([
    Type.Literal("PROPOSED"),
    Type.Literal("REJECTED"),
    Type.Literal("CONFIRMED"),
    Type.Literal("CONFIRMED_AUTOMATICALLY"),
  ]),
  publicationTarget: Type.Optional(
    Type.Array(Type.Union([Type.Literal("TBML")]))
  ),
  oEmbed: OEmbedSchema,
});

// ------------
// API SCHEMAS
// ------------
export const PostInboxRecordsRequestSchema = PubSubMessage;

// ------------
// Entity types
// ------------
export type InboxRecordEntity = Static<typeof InboxApiRecordSchema>;
// To create inbox records
export type InboxArticleEntity = Static<typeof InboxApiArticlePayload>;
// OEmbedType
export type InboxOEmbedSchema = Static<typeof OEmbedSchema>;
export type InboxOEmbedType = Static<typeof OEmbedType>;

// ------------
// API types
// ------------
export type PostInboxRecordsRequest = Static<
  typeof PostInboxRecordsRequestSchema
>;
