import { Type } from "@sinclair/typebox";

export const PubSubMessage = Type.Object({
  message: Type.Object({
    attributes: Type.Optional(Type.Any()),
    data: Type.String(),
    messageId: Type.String(),
    message_id: Type.String(),
    publishTime: Type.String(),
    publish_time: Type.String(),
  }),
  subscription: Type.String(),
});
