import * as React from "react";
import { OpenInNew, VisibilityOff } from "@mui/icons-material";
import { Text } from "../Typography";
import { Link } from "../Link";
import {
  LicenseExpireContent,
  LicenseExpireContainer,
  Content,
} from "./styles";

type Props = {
  text?: string;
  originalArticleUrl?: string | null;
};

export function LicenseExpired({
  text = "License for this content has expired",
  originalArticleUrl = undefined,
  ...props
}: Props & Omit<React.HTMLProps<HTMLDivElement>, "as">): JSX.Element {
  return (
    <LicenseExpireContainer aria-label="License expired" {...props}>
      <LicenseExpireContent>
        <VisibilityOff />
        <Content>
          <Text fontSize="inherit" color="inherit">
            {text}
          </Text>
          {originalArticleUrl && (
            <Link
              underline="always"
              fontSize="inherit"
              href={originalArticleUrl}
              target="_blank"
              aria-label={`Link to original article ${originalArticleUrl}`}
              title={originalArticleUrl}
              onClick={(e) => e.stopPropagation()}
            >
              Link to original article
              <OpenInNew fontSize="inherit" />
            </Link>
          )}
        </Content>
      </LicenseExpireContent>
    </LicenseExpireContainer>
  );
}
