import * as React from "react";
import { Tooltip } from "@mui/material";
import { Article } from "@tbml/hooks/useArticles";
import {
  mediaFormLabels,
  mediaSubFormLabels,
} from "@tbml/api-interface/inboxRecords/types";
import { TextXsmall } from "../Typography";
import { AlignedTextXsmall, Bold, Flex, ResizedMediaFormIcon } from "./styles";

function TooltipContent({
  mediaForm,
  mediaSubForm,
}: Pick<Article, "mediaForm" | "mediaSubForm">): JSX.Element {
  if (!mediaForm) {
    throw new Error("Article does not have a mediaForm");
  }

  return (
    <div>
      <TextXsmall paragraph>
        <Bold>Type:</Bold> {mediaSubFormLabels[mediaSubForm ?? "NA"]}
      </TextXsmall>
      <TextXsmall paragraph>
        <Bold>Form:</Bold> {mediaFormLabels[mediaForm]}
      </TextXsmall>
    </div>
  );
}

export type Props = {
  large?: boolean;
  tooltipOpen?: boolean;
} & Pick<Article, "mediaForm" | "mediaSubForm">;

export function ArticleSourceIcon({
  mediaForm,
  mediaSubForm,
  large = false,
  tooltipOpen = false,
  ...props
}: Props): JSX.Element {
  if (!mediaForm) {
    throw new Error("Article does not have a mediaForm");
  }

  return (
    <Tooltip
      open={tooltipOpen}
      title={
        <TooltipContent mediaForm={mediaForm} mediaSubForm={mediaSubForm} />
      }
      arrow
    >
      <Flex {...props}>
        <AlignedTextXsmall color="textSecondary" {...props}>
          <ResizedMediaFormIcon form={mediaForm} large={large} />
        </AlignedTextXsmall>
      </Flex>
    </Tooltip>
  );
}
