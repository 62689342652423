import { styled } from "@mui/material";
import tokens from "@tbml/tokens";
import { TextXsmall } from "../Typography";
import { MediaFormIcon } from "../MediaFormIcon";

export const VerticallyAligned = styled("div")`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Bold = styled(TextXsmall)`
  font-weight: bold;
`;

export const Flex = styled("div")`
  display: flex;
  align-items: center;
`;

export const AlignedTextXsmall = styled(TextXsmall)`
  display: flex;
`;

export const iconWrapperSize = 18;
export const largeIconWrapperSize = 32;

export const ResizedMediaFormIcon = styled(MediaFormIcon, {
  shouldForwardProp: (prop) => prop !== "large",
})<{ large?: boolean }>`
  font-size: ${({ large }) =>
    large
      ? `${tokens.font.shortTextSmall.fontSize.value}px`
      : `${tokens.font.shortTextXxsmall.fontSize.value}px`};
  color: ${tokens.color.textPrimaryDarkTheme.value};
`;
