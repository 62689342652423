import * as React from "react";
import { Avatar, CircularProgress, useTheme } from "@mui/material";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import { Article } from "@tbml/hooks/useArticles";
import { TextSmall, TextXsmall } from "../Typography";
import { ArticlePublishDate } from "../ArticlePublishDate";
import { Spacer } from "../Spacer";
import { Skeleton } from "../Skeleton";
import { ArticleSourceIcon } from "./ArticleSourceIcon";
import {
  Flex,
  iconWrapperSize,
  largeIconWrapperSize,
  VerticallyAligned,
} from "./styles";

export type Props = {
  article?: Article;
  timezone?: string;
  showAuthor?: boolean;
  relativeTime?: boolean;
  forceOpenTooltip?: boolean;
};

function ArticleMetaSkeleton({
  showAuthor,
}: {
  showAuthor?: boolean;
}): () => JSX.Element {
  return function ArticleMetaSkeletonComponent() {
    return (
      <Flex>
        <Skeleton
          width={showAuthor ? largeIconWrapperSize : iconWrapperSize}
          height={showAuthor ? largeIconWrapperSize : iconWrapperSize}
        />
        <Spacer size={showAuthor ? "horizontalS" : "horizontalXs"} />
        <div>
          <VerticallyAligned>
            <Skeleton width="140px" forText="shortTextXsmall" />
            <TextSmall color="textSecondary">&nbsp;·&nbsp;</TextSmall>
            <Skeleton width="80px" forText="shortTextXsmall" />
          </VerticallyAligned>
          {showAuthor && (
            <VerticallyAligned>
              <Skeleton width="120px" forText="shortTextXsmall" />
            </VerticallyAligned>
          )}
        </div>
      </Flex>
    );
  };
}

const defaultProps: { timezone: string } = {
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export function ArticleMeta({
  article,
  timezone = defaultProps.timezone,
  showAuthor,
  relativeTime,
  forceOpenTooltip,
  ...props
}: Props): JSX.Element {
  const theme = useTheme();
  const MemoizedArticleMetaSkeleton = React.useMemo(
    () => ArticleMetaSkeleton({ showAuthor }),
    [showAuthor]
  );
  const { withSkeleton } = useSkeleton({
    Skeleton: MemoizedArticleMetaSkeleton,
  });

  const includeAuthor = showAuthor && article?.author !== "";
  return withSkeleton(
    <Flex {...props}>
      <Avatar
        sx={{
          width: showAuthor ? largeIconWrapperSize : iconWrapperSize,
          height: showAuthor ? largeIconWrapperSize : iconWrapperSize,
          bgcolor: theme.palette.primary.dark,
        }}
      >
        {article ? (
          <ArticleSourceIcon
            mediaForm={article.mediaForm}
            mediaSubForm={article.mediaSubForm}
            large={includeAuthor}
            tooltipOpen={forceOpenTooltip}
          />
        ) : (
          <CircularProgress />
        )}
      </Avatar>
      <Spacer size={showAuthor ? "horizontalS" : "horizontalXs"} />
      <div>
        <VerticallyAligned>
          {article?.mediaName ? (
            <TextXsmall color="textPrimary" {...props}>
              {article.mediaName}
              {(article.mediaForm === "TELEVISION" ||
                article.mediaForm === "RADIO_STATION") &&
              article.mediaSubIssue
                ? `/${article.mediaSubIssue}`
                : ""}
              {article.mediaForm === "PRINT" && article.pages
                ? ` · Page(s): ${article.pages}`
                : ""}
            </TextXsmall>
          ) : (
            <Skeleton width="140px" forText="shortTextXsmall" />
          )}
          <TextSmall color="textPrimary">&nbsp;·&nbsp;</TextSmall>
          {article ? (
            <ArticlePublishDate
              article={article}
              timezone={timezone}
              relativeTime={relativeTime}
            />
          ) : (
            <Skeleton width="140px" forText="shortTextXsmall" />
          )}
        </VerticallyAligned>
        {includeAuthor && (
          <VerticallyAligned {...props}>
            {article?.author ? (
              <TextXsmall color="textSecondary">by {article.author}</TextXsmall>
            ) : (
              <Skeleton width="140px" forText="shortTextXsmall" />
            )}
          </VerticallyAligned>
        )}
      </div>
    </Flex>
  );
}
