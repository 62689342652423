import { styled } from "@mui/material";
import tokens from "@tbml/tokens";

export * from "./ListItem";

export const List = styled("ul")`
  padding-inline-start: ${tokens.spacing.gutterM.value};
  list-style-position: outside;
  margin: 0;
`;
