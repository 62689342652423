import * as React from "react";
import { gql } from "graphql-request";
import { differenceInDays, parseISO } from "date-fns";
import { HourglassBottom } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import { useIssues } from "@tbml/hooks/useIssues";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import { DAYS_UNTIL_ARCHIVE } from "@tbml/shared-dependencies/license";
import { LicenseStatus } from "./styles";

type LicenseInfoProps = React.PropsWithChildren<{
  isArchived?: boolean;
  issueId?: string | null;
}>;

export const fragmentName = "LicenseIssueInfoFields";
export const fragment = gql`
  fragment LicenseIssueInfoFields on Issue {
    refDate
  }
`;

export function LicenseInfoSkeleton(): JSX.Element {
  return (
    <div aria-label="Loading license...">
      <Skeleton width="100%" variant="rectangular">
        <LicenseStatus>
          <strong>License has expired</strong> - Images as well as original
          content of articles are hidden now
        </LicenseStatus>
      </Skeleton>
    </div>
  );
}

export function LicenseInfo({
  children = undefined,
  issueId = undefined,
  isArchived = undefined,
}: LicenseInfoProps): JSX.Element {
  const { query } = useIssues({ fragment, fragmentName });
  const needToFetchRefDates = isArchived === undefined && !!issueId;
  const { data, status } = query({
    filter: { ids: issueId ? [issueId] : [] },
    operationName: "GetIssueRefDates",
    enabled: needToFetchRefDates,
  });

  const { withSkeleton } = useSkeleton({
    isLoading: needToFetchRefDates && status === "pending",
    Skeleton: LicenseInfoSkeleton,
  });

  const [issue] = data?.issues ?? [];

  const isUnderLicense = React.useMemo(() => {
    if (!issue) return undefined;
    return (
      differenceInDays(new Date(), parseISO(issue.refDate)) > DAYS_UNTIL_ARCHIVE
    );
  }, [issue]);

  return withSkeleton(
    <div aria-label="License has expired info text">
      {(isUnderLicense || isArchived) && (
        <LicenseStatus icon={<HourglassBottom />}>
          <strong>License has expired</strong> - Images as well as original
          content of articles are hidden now
        </LicenseStatus>
      )}
      {children}
    </div>
  );
}
