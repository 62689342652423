import * as React from "react";
import { styled } from "@mui/material";
import tokens from "@tbml/tokens";
import { LongText } from "../Typography";

const SpacedListItem = styled("li")`
  margin: 0;
`;

const IndentedLongText = styled(LongText)`
  padding-left: ${tokens.spacing.paddingXs.value};
`;

export function ListItem({
  children = undefined,
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLLIElement>>): JSX.Element {
  return (
    <SpacedListItem {...props}>
      <IndentedLongText>{children}</IndentedLongText>
    </SpacedListItem>
  );
}
