import * as React from "react";
import format from "date-fns-tz/format";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import zonedTimeToUtc from "date-fns-tz/zonedTimeToUtc";
import { styled } from "@mui/material";
import { formatDistance, parseISO } from "date-fns";
import { Article } from "@tbml/hooks/useArticles";
import { TextXsmall } from "../Typography";

export type Props = {
  article: Article;
  timezone?: string;
  relativeTime?: boolean;
  publishedTag?: boolean;
};

const NoWrapText = styled(TextXsmall)`
  white-space: nowrap;
`;

export function ArticlePublishDate({
  article: { publishedAt },
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  relativeTime = false,
  ...props
}: Props): JSX.Element {
  return (
    <NoWrapText color="textPrimary" {...props}>
      {relativeTime
        ? formatDistance(
            zonedTimeToUtc(parseISO(publishedAt), timezone, {
              timeZone: timezone,
            }),
            zonedTimeToUtc(new Date(), timezone, { timeZone: timezone }),
            {
              addSuffix: true,
            }
          )
        : format(
            utcToZonedTime(publishedAt, timezone, {
              timeZone: timezone,
            }),
            "yyyy-MM-dd, HH:mm",
            { timeZone: timezone }
          )}
    </NoWrapText>
  );
}
