import { styled, Alert } from "@mui/material";
import tokens from "@tbml/tokens";

export const LicenseStatus = styled(Alert)`
  justify-content: center;
`;

LicenseStatus.defaultProps = {
  severity: "warning",
};

export const LicenseExpireContent = styled("div", {
  shouldForwardProp: (propName) => propName !== "row",
})<{
  row?: boolean;
}>`
  display: flex;
  flex-direction: row;
  place-items: center;
  align-items: center;
  width: 100%;
  gap: ${tokens.spacing.paddingS.value};
  color: ${({ theme }) => theme.palette.text.disabled};
`;

export const Content = styled("div")`
  display: grid;
  font-size: ${tokens.font.shortTextSmall.fontSize.value};
`;

export const LicenseExpireContainer = styled("div")`
  display: grid;
  place-items: flex-start;
`;
