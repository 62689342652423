import * as React from "react";
import { styled } from "@mui/material";
import {
  HelpOutline,
  Radio,
  ArtTrack,
  Laptop,
  Language,
  MenuBook,
  LiveTv,
} from "@mui/icons-material";
import { mediaFormLabels } from "@tbml/api-interface/inboxRecords/types";

const ResizedLaptop = styled(Laptop)`
  font-size: 1em;
`;
const ResizedHelpOutlineIcon = styled(HelpOutline)`
  font-size: 1em;
`;
const ResizedRadio = styled(Radio)`
  font-size: 1em;
`;
const ResizedArtTrack = styled(ArtTrack)`
  font-size: 1em;
`;
const ResizedLanguage = styled(Language)`
  font-size: 1em;
`;
const ResizedMenuBook = styled(MenuBook)`
  font-size: 1em;
`;
const ResizedLiveTv = styled(LiveTv)`
  font-size: 1em;
`;

export type Props = {
  form: keyof typeof mediaFormLabels;
};

export function MediaFormIcon({ form, ...props }: Props): JSX.Element {
  switch (form) {
    case "ONLINE":
      return <ResizedLaptop {...props} />;
    case "NEWS_AGENCY":
      return <ResizedArtTrack {...props} />;
    case "PRINT":
      return <ResizedMenuBook {...props} />;
    case "RADIO_STATION":
      return <ResizedRadio {...props} />;
    case "SOCIAL_MEDIA":
      return <ResizedLanguage {...props} />;
    case "TELEVISION":
      return <ResizedLiveTv {...props} />;
    default:
      return <ResizedHelpOutlineIcon {...props} />;
  }
}
