import * as React from "react";
import { styled } from "@mui/material";
import tokens from "@tbml/tokens";

export type Props = { lines?: number; font?: keyof typeof tokens.font };

/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable property-no-vendor-prefix */
const Box = styled("div")<{ lines?: number; font?: keyof typeof tokens.font }>`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ lines }) => lines};
  line-height: ${({ font }) =>
    font ? tokens.font[font].lineHeight.value : false};
  max-height: ${({ font, lines }) => {
    if (!lines) return false;
    if (!font) return `${lines * 1.15}rem`;
    const unit =
      tokens.font[font].fontSize.unit === "pixel"
        ? "px"
        : tokens.font[font].fontSize.unit;

    const lineHeightFactor =
      tokens.font[font].lineHeight.unit === "percent"
        ? tokens.font[font].lineHeight.original.value / 100
        : tokens.font[font].lineHeight.original.value;

    return `${
      lines * tokens.font[font].fontSize.original.value * lineHeightFactor
    }${unit}`;
  }};
`;
/* stylelint-enable value-no-vendor-prefix */
/* stylelint-enable property-no-vendor-prefix */

const inlineBlockElements = (children: React.ReactNode): React.ReactNode =>
  React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) return child;

    // should stay inline and don't need a linebreak appended
    if (child.type === "span") return child;
    if (child.type === "br") return child;

    // divs need to be flattened for safari
    if (child.type === "div") return inlineBlockElements(child.props.children);

    return (
      <>
        {React.cloneElement<{ style: React.CSSProperties }>(
          child as React.DetailedReactHTMLElement<
            { style: React.CSSProperties },
            HTMLElement
          >,
          {
            style: { display: "inline" },
          }
        )}
        <br />
      </>
    );
  });

export function Clamp({
  children = undefined,
  lines = undefined,
  ...props
}: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <Box {...props} lines={lines}>
      {lines ? inlineBlockElements(children) : children}
    </Box>
  );
}
