import React from "react";
import {
  BlockGroup,
  DeltaInsertOp,
  ListGroup,
  ListItem,
} from "quill-delta-to-html";
import { TDataGroup } from "quill-delta-to-html/dist/commonjs/grouper/group-types";
import { StringMap } from "quill";
import tokens from "@tbml/tokens";
import { DeltaData } from "@tbml/api-interface/delta";
import { List, ListItem as ListItemComponent } from "../List";
import { Spacer } from "../Spacer";
import {
  StyledInlineText,
  DeltaParagraph,
  GlpyhCharacter,
  StyledLink,
} from "./styles";

type RenderOptions = {
  font: keyof typeof tokens.font;
  glyph?: boolean;
  groupIndex: number;
};

const isGlyphable = (character: string): boolean =>
  character.length === 1 && !!character.match(/[a-z0-9]/i);

type ExternalLinkProps = {
  attributes: StringMap;
  href: string;
  children: React.ReactNode;
};
function ExternalLink({
  attributes,
  href,
  children,
  ...rest
}: ExternalLinkProps) {
  return (
    <StyledLink
      as="a"
      attributes={attributes}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(event) => {
        event.stopPropagation();
      }}
      {...rest}
    >
      {children}
    </StyledLink>
  );
}

const renderBlockGroup = (
  blockGroup: BlockGroup,
  { font, glyph, groupIndex }: RenderOptions
) => {
  const aggregatedOps = [
    ...(blockGroup.op ? [blockGroup.op] : []),
    ...blockGroup.ops,
  ];
  const paragraphs = aggregatedOps.reduce<DeltaData["ops"][]>((acc, op, i) => {
    if (acc.length === 0) return [[op]];
    const last = acc.at(-1) ?? [];
    if (i === aggregatedOps.length - 1 && op.insert.value === "\n") {
      return acc;
    }
    if (op.insert.value === "\n" && last.length > 0) {
      return [...acc, []];
    }
    const remaining = acc.slice(0, -1);
    return [...remaining, [...last, op]];
  }, []);

  return (
    <>
      {paragraphs.map((ops, lineNumber) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={lineNumber}>
          {lineNumber > 0 && <Spacer size="verticalXs" />}
          <DeltaParagraph font={font}>
            {ops.map(({ attributes, insert: { value } }, index) => {
              if (
                groupIndex === 0 &&
                lineNumber === 0 &&
                index === 0 &&
                glyph &&
                value &&
                isGlyphable(value[0])
              ) {
                const [glyphedCharacter, ...rest] = value.split("");

                const nodeWithGlyph = (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    <GlpyhCharacter>{glyphedCharacter}</GlpyhCharacter>
                    <StyledInlineText attributes={attributes}>
                      {rest.join("")}
                    </StyledInlineText>
                  </React.Fragment>
                );
                if (typeof attributes?.link === "string") {
                  return (
                    <ExternalLink
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      attributes={attributes}
                      href={attributes.link}
                    >
                      {nodeWithGlyph}
                    </ExternalLink>
                  );
                }
                return nodeWithGlyph;
              }

              if (attributes?.link) {
                return (
                  <ExternalLink
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    attributes={attributes}
                    href={attributes.link}
                  >
                    {value}
                  </ExternalLink>
                );
              }

              return value === "\n" ? (
                // eslint-disable-next-line react/no-array-index-key
                <br key={index} />
              ) : (
                // eslint-disable-next-line react/no-array-index-key
                <StyledInlineText attributes={attributes} key={index}>
                  {value}
                </StyledInlineText>
              );
            })}
          </DeltaParagraph>
          {lineNumber < paragraphs.length - 1 && <Spacer size="verticalXs" />}
        </React.Fragment>
      ))}
    </>
  );
};

const isListItem = (item: TDataGroup | null): item is ListItem =>
  !!(item as ListItem)?.innerList || !!(item as ListItem)?.item;

const isListGroup = (item: TDataGroup): item is ListGroup =>
  !!(item as ListGroup).items;

const isBlockGroup = (item: TDataGroup): item is BlockGroup =>
  !(item as ListItem).innerList &&
  !(item as ListGroup).items &&
  (!!(item as BlockGroup).ops || !!(item as BlockGroup).op);

const renderList = (
  listElement: ListGroup | ListItem,
  renderOptions: RenderOptions
) => {
  if (isListItem(listElement)) {
    return (
      <ListItemComponent>
        {!!listElement.item &&
          renderBlockGroup(listElement.item, renderOptions)}
        {!!listElement.innerList &&
          renderList(listElement.innerList, renderOptions)}
      </ListItemComponent>
    );
  }

  if (isListGroup(listElement)) {
    // merge list items until there is one that is just a newline
    const mergedListElements = listElement.items
      .reduce<(ListItem | null)[]>((acc, listGroupElement) => {
        // if it is a newline op,
        if (listGroupElement.item.op.insert?.value === "\n") {
          // ops themselves are empty, create a separator element
          if (listGroupElement.item.ops[0].insert.value === "\n") {
            return [...acc, null];
          }

          // ops have content, so this is a new list item
          return [
            ...acc,
            {
              item: {
                ops: listGroupElement.item.ops,
                op: new DeltaInsertOp(""),
                innerList: null,
              },
              innerList: listGroupElement.innerList,
            },
          ];
        }

        const last: ListItem | null = acc.slice(-1)[0] ?? null;
        const remaining: (ListItem | null)[] = acc.slice(0, -1);

        // if previous element is null, it is a separator. append as new element
        if (!last) return [...acc, listGroupElement];

        // else, merge block into the last listGroupElement
        return [
          ...(remaining ?? []),
          {
            ...last,
            item: {
              ...last.item,
              ops: [
                ...(last.item?.ops.filter(
                  (subOps) => subOps.insert.value !== "\n"
                ) ?? []),
                listGroupElement.item.op,
                ...(listGroupElement.item.ops.filter(
                  (subOps) => subOps.insert.value !== "\n"
                ) ?? []),
              ],
              innerList: null,
            },
          },
        ];
      }, [] as ListItem[])
      .filter(isListItem);

    return (
      <>
        {renderOptions.groupIndex > 0 && <Spacer size="verticalXs" />}
        <List>
          {mergedListElements.map((item, itemIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={itemIndex}>
              {renderList(item, renderOptions)}
            </React.Fragment>
          ))}
        </List>
        <Spacer size="verticalXs" />
      </>
    );
  }

  return null;
};

export const renderDeltaGroups = (
  group: TDataGroup,
  renderOptions: RenderOptions
): JSX.Element | null => {
  if (isListGroup(group) || isListItem(group))
    return renderList(group, renderOptions);
  if (isBlockGroup(group)) return renderBlockGroup(group, renderOptions);

  throw new Error("Can not parse delta group");
};
