import * as React from "react";
import { styled } from "@mui/material";
import tokens from "@tbml/tokens";
import { Attributes, Props } from "./index";

export type FormattedSpanProps = {
  attributes: Attributes;
};

const fontWeight = ({ attributes }: FormattedSpanProps) =>
  attributes?.bold ? "bold" : "";

const fontStyle = ({ attributes }: FormattedSpanProps) =>
  attributes?.italic ? "italic" : "";

const textDecoration = ({ attributes }: FormattedSpanProps) => {
  const underline = attributes?.underline ? "underline" : "";
  const strikethrough = attributes?.strike ? "line-through" : "";

  return `${underline} ${strikethrough}`.trim();
};

export const StyledInlineText = styled("span")<FormattedSpanProps>`
  font-weight: ${fontWeight};
  font-style: ${fontStyle};
  text-decoration: ${textDecoration};
`;

export const StyledLink = styled(StyledInlineText)<{
  href: string;
  target?: string;
  rel?: string;
}>`
  color: ${({ theme }) => theme.palette.primary.main};
  :hover {
    opacity: 0.7;
  }
`;

export const TextComponent = styled("p")<{ font: keyof typeof tokens.font }>`
  margin: 0;
  font-family: ${({ font }) => tokens.font[font].fontFamily.value};
  font-size: ${({ font }) => tokens.font[font].fontSize.value};
  font-weight: ${({ font }) => tokens.font[font].fontWeight.value};
  text-decoration: ${({ font }) => tokens.font[font].textDecoration.value};
  font-style: ${({ font }) => tokens.font[font].fontStyle.value};
  font-stretch: ${({ font }) => tokens.font[font].fontStretch.value};
  letter-spacing: ${({ font }) =>
    tokens.font[font].letterSpacing.attributes.em};
  line-height: ${({ font }) => tokens.font[font].lineHeight.value};
  text-indent: ${({ font }) => tokens.font[font].paragraphIndent.value};
  padding: ${({ font }) => tokens.font[font].paragraphSpacing.value};
  text-transform: ${({ font }) => tokens.font[font].textCase.value};
`;

export function DeltaParagraph({
  attributes,
  ...props
}: Attributes & Required<Pick<Props, "font">>): JSX.Element {
  return <TextComponent {...props} />;
}

const textLineHeight =
  tokens.font.longTextDefault.fontSize.original.value *
  (tokens.font.longTextDefault.lineHeight.original.value / 100);

const textFontSize = tokens.font.longTextDefault.fontSize.original.value;

export const GlpyhCharacter = styled("span")`
  font-family: ${tokens.font.h2.fontFamily.value};
  font-weight: ${tokens.font.h2.fontWeight.value};
  text-decoration: ${tokens.font.h2.textDecoration.value};
  font-style: ${tokens.font.h2.fontStyle.value};
  font-stretch: ${tokens.font.h2.fontStretch.value};
  letter-spacing: ${tokens.font.h2.letterSpacing.attributes.em};
  text-indent: ${tokens.font.h2.paragraphIndent.value};
  text-transform: ${tokens.font.h2.textCase.value};
  line-height: ${textLineHeight * 1.9}px;
  font-size: ${textLineHeight + textFontSize * 1.1}px;
  padding-right: 0.25ch;
  transform: translateY(-${(textLineHeight - textFontSize) / 6}px);
  float: left;
  clear: both;
`;

export const ClearBoth = styled("span")`
  display: block;
  clear: both;
`;
