import * as React from "react";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import tokens from "@tbml/tokens";
import { DeltaData } from "@tbml/api-interface/delta";
import { Skeleton } from "../Skeleton";
import { Spacer } from "../Spacer";
import { Clamp } from "../Clamp";
import { renderDeltaGroups } from "./renderDeltaGroups";

export type Attributes = DeltaData["ops"][0]["attributes"];

export type Props = {
  editorialContent: DeltaData | null;
  renderedLines?: number;
  clampLines?: number;
  font?: keyof typeof tokens.font;
  glyph?: boolean;
};

const defaultProps: Required<Pick<Props, "font" | "glyph">> = {
  font: "longTextDefault",
  glyph: false,
};

function DeltaSkeleton() {
  return (
    <div>
      <Skeleton width="100%" forText="h5" />
      <Skeleton width="100%" forText="h5" />
      <Skeleton width="100%" forText="h5" />
      <Skeleton width="80%" forText="h5" />
      <Spacer />
      <Skeleton width="100%" forText="h5" />
      <Skeleton width="100%" forText="h5" />
      <Skeleton width="30%" forText="h5" />
      <Spacer />
      <Skeleton width="100%" forText="h5" />
      <Skeleton width="60%" forText="h5" />
    </div>
  );
}
export function DeltaContent({
  renderedLines,
  clampLines,
  editorialContent,
  font = defaultProps.font,
  glyph = defaultProps.glyph,
  ...props
}: Props): JSX.Element {
  const { withSkeleton } = useSkeleton({
    Skeleton: DeltaSkeleton,
  });

  const converter = new QuillDeltaToHtmlConverter(editorialContent?.ops ?? []);
  const groups = converter.getGroupedOps();

  const groupsToProcess =
    renderedLines && groups.length >= renderedLines
      ? groups.slice(0, renderedLines)
      : groups;

  return withSkeleton(
    <Clamp {...props} lines={clampLines} font={font}>
      {groupsToProcess.map((group, groupIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={groupIndex}>
          {renderDeltaGroups(group, { font, glyph, groupIndex })}
        </React.Fragment>
      ))}
    </Clamp>
  );
}

DeltaContent.defaultProps = defaultProps;
